// pages
export const ROUTES = {
	HOME_ABOUT: '/',
	RESUME: '/resume',
	ART: 'https://rnehoran.github.io/art/',
	CONTACT: '/contact',
}

export const PAGES = {
	HOME_ABOUT: 0,
	RESUME: 1,
	ART: 2,
	CONTACT: 3,
}

// colors
export const COLORS = {
	BACKGROUND: "#221b2e",
	ACCENT: "#ab75d3",
}
