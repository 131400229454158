import React from 'react';
import './App.css';
import {
	BrowserRouter as Router,
	Route,
	Switch
} from 'react-router-dom';
import Page from './components/page';
import { ROUTES, PAGES } from './constants/constants';
import Redirect from './utilities/redirect';
import { COLORS } from './constants/constants';

const App = () => (
	<div className="App" style={{ backgroundColor: COLORS.BACKGROUND}}>
		<Router>
			<Switch>
				<Route exact path={ROUTES.HOME_ABOUT} component={() => <Page page={PAGES.HOME_ABOUT} />} />
				<Route path={ROUTES.RESUME} component={() => <Page page={PAGES.RESUME} />} />
				<Route path={ROUTES.ART} component={() => <Page page={PAGES.ART} />} />
				<Route component={() => <Redirect path={ROUTES.HOME_ABOUT}/>} />
			</Switch>
		</Router>
	</div>
);

export default App;
