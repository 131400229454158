import React, { useEffect, useState } from 'react';
import Navigation from './navigation';
import Content from './content';
import Header from './header';
import Footer from './footer';
import Ifthen from '../utilities/ifthen';

const Page = props => {

	const [mobile, setMobile] = useState(false);

	const updateIfMobile = () => setMobile(window.innerWidth < 700);

	useEffect(() => {
		updateIfMobile()
		window.addEventListener("resize", updateIfMobile)
		return () => {
			window.removeEventListener("resize", updateIfMobile)
		}
	}, []);

	return (
		<div style={{ display: "flex", flexDirection: "column", minHeight: "100vh", }}>

			{/* header + navigation */}
			<Ifthen
				if={mobile}
				then={
					<div style={{ display: "flex", flexDirection: "column", alignItems: "center", }}>
						<Header page={props.page} mobile={true} />
						<Navigation page={props.page} mobile={true} />
					</div>
				}
				else={
					<div style={{ display: "flex", justifyContent: "space-between", }}>
						<Header page={props.page} />
						<Navigation page={props.page} />
					</div>
				}
			/>

			{/* page content below header */}
			<Content page={props.page} />

			<Footer />
		</div>
	);
}

export default Page;
