import React from 'react';
import SwitchArray from '../utilities/switchArray';
import { PAGES } from '../constants/constants';
import Ifthen from '../utilities/ifthen';
import about0 from '../images/about0.jpg';
import resume0_title from '../images/resume0_title.png';
import resume1 from '../images/resume1.png';
import resume2_title from '../images/resume2_title.png';
import resume3 from '../images/resume3.png';
import resume4 from '../images/resume4.png';
import resume5_title from '../images/resume5_title.png';
import resume6 from '../images/resume6.png';
import resume7 from '../images/resume7.png';
import resume8 from '../images/resume8.png';
import resume9 from '../images/resume9.png';
import art0 from '../images/art0.png';
// import contact0 from '../images/contact0.png';

const ImageList = props => (
	<div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", }}>
		{props.images.map((image, i) => {
			const isLink = props.links !== undefined && props.links[i] !== "";
			const hasSize = props.sizes !== undefined && props.sizes[i] !== 0;
			const isTitle = props.areTitles !== undefined && props.areTitles[i];
			const opacity = props.opacities !== undefined ? props.opacities[i] : 1;
			return (
				<Ifthen
					key={i}
					if={isLink}
					then={
						<a
							href={isLink ? props.links[i] : ""}
							target="_blank" rel="noopener noreferrer"
							className="clickable"
							style={{
								marginBottom: (isTitle ? "1vw" : "1.5vw"),
								marginTop: (i === 0 ? "2vw" : (isTitle ? "4vw" : 0)),
								transitionDuration: "0.15s",
							}}
						>
							<img
								alt=""
								src={image}
								className="content"
								style={{ opacity, width: (hasSize ? props.sizes[i] + "vw" : "75vw"), transitionDuration: "0.15s", }}
							/>
						</a>
					}
					else={
						<img
							alt=""
							src={image}
							className="content"
							style={{
								opacity,
								width: (hasSize ? props.sizes[i] + "vw" : "75vw"),
								marginBottom: (isTitle ? "1vw" : "1.5vw"),
								marginTop: (i === 0 ? "2vw" : (isTitle ? "4vw" : 0)),
								transitionDuration: "0.15s",
							}}
						/>
					}
				/>
			);
		})}
	</div>
);

const Content = props => {
	return (
		<div style={{ marginBottom: "2vw", marginTop: "2vw", }}>
			<SwitchArray
				value={props.page}
				equals={[PAGES.HOME_ABOUT, PAGES.RESUME, PAGES.ART]}//, PAGES.CONTACT]}
				then={[
					<ImageList images={[about0]} sizes={[50]} opacities={[0.9]} />,
					<ImageList
						images={[resume0_title, resume1, resume2_title, resume3, resume4, resume5_title, resume6, resume7, resume8, resume9,]}
						links={["", "https://www.microsoft.com/", "", "", "", "", "https://www.palantir.com/", "https://www.qualcomm.com/", "https://www.sandia.gov/", "https://www.pulsesecure.net/"]}
						areTitles={[true, false, true, false, false, true, false, false, false, false]}
						opacities={[1, 0.9, 1, 0.9, 0.9, 1, 0.9, 0.9, 0.9, 0.9]}
					/>,
					<ImageList images={[art0]} links={["https://drive.google.com/drive/folders/0B01yLbkETyknOTY1RU1wanJ0NWs"]} />,
					// <ImageList images={[contact0]} sizes={[40]} />,
				]}
			/>
		</div>
	);
}

export default Content;
