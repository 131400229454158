import React from 'react';
import about from '../images/about.png';
import resume from '../images/resume.png';
import art from '../images/art.png';
import { ROUTES, PAGES } from '../constants/constants';
import Ifthen from '../utilities/ifthen';

const links = [
	[PAGES.HOME_ABOUT, ROUTES.HOME_ABOUT, about],
	[PAGES.RESUME, ROUTES.RESUME, resume],
	[PAGES.ART, ROUTES.ART, art],
];

const Navigation = props => (
	<div style={{
		display: "flex",
		justifyContent: "space-around",
		alignItems: "center",
		width: (props.mobile ? "90vw" : "45vw"),
		flexWrap: "wrap",
		marginRight: (props.mobile ? 0 : "5vw"),
	}}>
		{links.map((link) => (
			<a
				href={link[1]}
				key={link[0]}
				// open art in new tab
				{...(link[0] === PAGES.ART ? {target: "_blank", rel: "noopener noreferrer"} : {})}
			>
				<Ifthen
					if={props.page === link[0]}
					then={
						<img alt="" src={link[2]} className="nav-link purple" style={{ height: "5vw", minHeight: 60, opacity: 1, }} />
					}
					else={
						<img alt="" src={link[2]} className="nav-link" style={{ height: "5vw", minHeight: 60, filter: "brightness(1000%)", }} />
					}
				/>
			</a>
		))}
	</div>
)

export default Navigation;
