import React from 'react';
import logo from '../images/logo.jpg';
import { ROUTES } from '../constants/constants';

const Header = props => (
	<a href={ROUTES.HOME_ABOUT}>
		<img
			alt=""
			src={logo}
			style={{ height: "10vw", minHeight: 100, marginTop: "4vh", marginLeft: (props.mobile ? 0 : "3vw"), opacity: 0.9, transitionDuration: "0.15s", }}
			className="header"
		/>
	</a>
)

export default Header;
