import React from 'react';
// import contact from '../images/contact.png';
// import { ROUTES } from '../constants/constants';

const Footer = () => (
	<div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "flex-end", flexGrow: "1", minHeight: "10vh" }}>
		{/* <a href={ROUTES.CONTACT}>
			<img alt="" src={contact} className="nav-link" style={{ height: "5vh", marginBottom: "2vh", }} />
		</a> */}
	</div>
);

export default Footer;
