import React from 'react';

const SwitchArray = props => {
	for (let i = 0; i < props.equals.length; i++) {
		if (props.value === props.equals[i]) {
			return props.then[i];
		}
	}
	console.error("SwitchArray value unmatched { value:", props.value, ", possibilities (equals): ", props.equals, " }");
	return <div></div>;
}

export default SwitchArray;
